<template>
	<div class="p-5">
		<div class="row p-md-5">
			<div class="col-lg-4 col-md-5 col-sm-9 m-auto">
				<form class="box" @submit.prevent="onSubmit">
					<div class="text-center logo">
						<img src="@/assets/icon.png" alt="logo" class="w-100">
					</div>
					<div v-if="user.email && user.locked" class="form-group text-center p-2">
						<h5>{{ user.name }}</h5>
					</div>
					<div v-else class="form-group">
						<label for="email">Email</label>
						<input type="email" id="email" v-model="email" placeholder="Enter email address"
							class="form-control">
					</div>
					<div class="form-group">
						<label for="password" v-if="!user.locked">Password</label>
						<input type="password" id="password" v-model="password" placeholder="Enter password"
							class="form-control">
					</div>
					<div class="form-group">
						<button type="submit" class="btn btn-primary btn-block"
							:disabled="isBusy || !(email && password.length > 3)">Login <b-spinner v-if="isBusy" small
								class="ml-1"></b-spinner></button>
					</div>
					<div v-if="user.locked" class="text-center">
						<small class="text-danger pointer" @click="logout">Login with another account.</small>
					</div>
				</form>
			</div>
		</div>
		<div class="footer">
			<div class="row">
				<div class="col-sm-4">
					&copy; {{ new Date().getFullYear() }} - All Rights Reserved.
				</div>
				<div class="col-sm-4 text-center">
					Powered by <b-link href="https://shypmax.com" target="_new">Shypmax</b-link>
				</div>
				<div class="col-sm-4 text-right">
					Version: 2.5.2 (174)
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import router from '../router'
export default {
	name: "Login",
	data() {
		return {
			isBusy: false,
			email: this.$store.getters.user.email || '',
			password: '',
		}
	},

	mounted() {
		this.redirectUser();
	},
	methods: {
		async onSubmit() {
			try {
				this.isBusy = true
				const res = await this.axios.post(`/login`, { emailID: this.email, password: this.password })
				if (res.data.success) {
					const user = {
						token: res.data.accessToken,
						email: res.data.email,
						name: res.data.name,
						id: res.data.userId,
						db_id: res.data.db_id,
						role: res.data.role,
						locked: false
					}
					if (user && user?.id && user?.role == 5) {
						this.$router.push('/inscan');
						this.$store.dispatch('login', user);
					} else if (user && user?.id && user?.role == 45) {
						this.$router.push('/inscan-hub');
						this.$store.dispatch('login', user);
					} else if (user && user?.id && user?.role == 56) {
						this.$router.push('/import-origin-scan');
						this.$store.dispatch('login', user);
					} else {
						this.popToast('Failed!', 'You are not allowed to log in. Please get in touch with the administrator.', 'danger');
					}
				} else {
					this.popToast('Failed!', res.data.message, 'danger');
				}
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
				console.error(error);
			}
		},

		redirectUser() {
			if (this.user?.email && !this.user.locked) {
				if (this.user && this.user?.id && this.user?.role == 5) {
					router.push({ name: 'InScan' })
				}
				else if (this.user && this.user?.id && this.user?.role == 45) {
					router.push({ name: 'InScanHub' });
				}
				else if (this.user && this.user?.id && this.user?.role == 56) {
					router.push({ name: 'ImportOriginScan' });
				}
				else {
					this.popToast('Failed!', 'You are not allowed to log in. Please get in touch with the administrator.', 'danger');
				}
			}
		},

		logout() {
			if (confirm('Are you sure to log out?')) {
				this.$store.dispatch('logout');
			}
		}
	},

	computed: {
		user() {
			return this.$store.getters.user;
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
	box-shadow: 0 0 1px 1px #0000001f;
	background: #ffffff;
	padding: 30px;
	border-radius: 5px;
}

.logo {
	background: #fff;
	border-radius: 50%;
	margin: auto;
	margin-top: -80px;
	width: 100px;
	height: 100px;
	padding: 30px;
	display: flex;
	align-items: center;
	box-shadow: 0 -1px 1px #0000001f;
}

.footer {
	position: absolute;
	bottom: 0;
	font-size: 13px;
	left: 0;
	padding: 10px;
	right: 0;
	color: #aaa;
}
</style>